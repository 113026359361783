<template>
  <div>
    <GoodsReturnStowing
      v-if="activeCommand && activeCommand.id"
      :active-command="activeCommand"
      @done="commandDone"
    />
    <GoodsReturnReceiveBasket v-else @submit="getActiveCommand" />
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "Stowing",
  components: {
    GoodsReturnReceiveBasket: () =>
      import("@/components/goods_return/ReceiveBasket"),
    GoodsReturnStowing: () => import("@/components/goods_return/Stowing"),
  },
  data: () => ({
    activeCommand: null,
    isLoading: false,
  }),
  methods: {
    async getActiveCommand() {
      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-return/v1/get-active-command"
        );
        this.activeCommand = { ...data };
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    commandDone() {
      this.activeCommand = null;
    },
  },
  mounted() {
    this.getActiveCommand();
  },
};
</script>

<style scoped></style>
