import { render, staticRenderFns } from "./Stowing.vue?vue&type=template&id=57fa0cdc&scoped=true"
import script from "./Stowing.vue?vue&type=script&lang=js"
export * from "./Stowing.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57fa0cdc",
  null
  
)

export default component.exports